@use "../config/" as *;
@each $icon,
$value in $player-skill {
    .#{$icon} {
        .player-role-info {
            &::before {
                content: $value;
            }
        }
    }
}
.squad {
    @extend %pure-white-900-bg;
    @extend %p-4;
    .tabs-secondary {
        @extend %pb-3;
        @extend %gap-3;
        .tab {
            &-name {
                border: .1rem solid var(--neutral-600);
                min-height: 3rem;
                height: auto;
                @extend %half-radius;
                &.tab-active {
                    border: .1rem solid var(--primary-300);
                }
            }
        }
    }
    .table {
        overflow: unset;
        &-head {
            height: 4.1rem;
            @extend %mb-4;
            @extend %px-3;
            @extend %neutral-50-bg;
            @extend %half-radius;
            .table-row {
                @extend %h-100;
            }
            .text {
                @extend %neutral-800;
                @extend %font-14-pm;
                @extend %capitalize;
            }
            .table-data {
                @extend %h-100;
            }
        }
        &-data {
            @extend %flex-fs-c;
        }
        &-body {
            .table-row {
                height: 6.6rem;
                border: .1rem solid var(--neutral-1000);
                box-shadow: 0 0 1rem 0 clr(neutral-900, 1);
                @extend %relative;
                @extend %pure-white-900-bg;
                @extend %half-radius;
                @extend %flex-c-c;
                @extend %px-3;
                &:not(:last-child) {
                    @extend %mb-4;
                }
                .player-role-info::before {
                    font: 1.4rem/1 $font-icon;
                    @extend %secondary-1000;
                }
            }
        }
    }
    .team {
        &-wrapper {
            @extend %gap-5;
            @extend %flex-column;
        }
        &-img {
            width: 2.4rem;
            height: 1.5rem;
            @extend %mr-2;
        }
    }
    .player {
        &-thumbnail {
            width: 4.9rem;
            height: 4.9rem;
            border: .1rem solid clr(primary-300);
            @extend %circle-radius;
            @extend %secondary-50-bg;
            @extend %relative;
            .image {
                @extend %circle-radius;
            }
        }
        &-role-info {
            .text {
                @extend %font-0;
            }
            &:before {
                right: 2rem;
                @extend %pos-y-center;
            }
        }
        &-name {
            @extend %neutral-800;
            @extend %font-14-pm;
            @extend %px-2;
        }
    }
}
@include mq(col-md) {
    .squad {
        .tab-head {
            display: none;
        }
        .team-wrapper {
            gap: var(--space-4);
            flex-direction: row;
        }
        .table-wrap {
            flex: 1;
        }
    }
}