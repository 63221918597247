@use "../config/" as *;

.waf-cricketscorecard {
    .matchinfo {
        @extend %p-4;
        @extend %pure-white-900-bg;
        &-item {
            @extend %py-4;
            @extend %flex-sb-c;
            &:not(:last-child) {
                border-bottom: .1rem solid clr(secondary-800, 2);
            }
        }
        .label {
            @extend %capitalize;
            @extend %font-12-pm;
            @extend %neutral-800;
        }
        .info {
            width: 75%;
            @extend %text-right;
            @extend %font-12-pb;
            @extend %neutral-800;
            &-wrap {
                width: 75%;
                @extend %gap-1;
                @extend %flex-column;
            }
        }
        .item {
            &-info {
                @extend %gap-3;
                @extend %flex-n-c;
            }
            &-img {
                width: 4.5rem;
                height: 4.5rem;
                @extend %secondary-50-bg;
                @extend %circle-radius;
            }
            &-name {
                @extend %capitalize;
                @extend %font-14-pm;
                @extend %neutral-800;
            }
            &-score {
                @extend %font-14-pb;
                @extend %neutral-800;
            }
        }
        .venue {
            .info {
                &::before {
                    @extend %mr-1;
                    @include icon(stadium);
                }
            }
        }
        .date {
            .info-wrap {
                display: inline;
                line-height: 1.8rem;
                @extend %text-right;
            }
            .info-date {
                &::before {
                    @extend %mr-1;
                    @include icon(timer); 
                }
            }
            .info-time {
                border-left: .1rem solid var(--neutral-800);
                @extend %pl-1;
            }
            .info  {
                display: inline;
            }
        }
    }
}
@include mq(col-md) {
    .waf-cricketscorecard {
        .matchinfo {
            .info,.label {
                font-size: 1.4rem;
            }
            .info-wrap,.info,.item-info {
                width: 60%;
                text-align: left;
            }
            .date .info-wrap {
                text-align: left;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-cricketscorecard {
        .matchinfo {
            .info-wrap,.info,.item-info {
                width: 50%;
            }
        }
    }
}