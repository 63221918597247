@forward "./main-scss/";
@forward "./new-matchcenter/";
@forward "./listing/series-listing";
@forward "./listing/related-list";
@forward "./listing/listing-common";
@forward "./swiper/swiper-bundle";
@forward "./partials/news-details";
@use "./config/" as *;
.waf-cricketscorecard {
    @extend %mb-4;
    .waf-head {
        @extend %d-none;
    }
    .detail-info-section {
        .head .tab-section {
            @extend %my-4;
        }
    }
    .share-page {
        .share-icon {
            cursor: pointer;
        }
        .close {
            @extend %secondary-600-bg;
        }
        .social {
            &-icon {
                @extend %pure-white-900;
                &:has(.icon-b-email) {
                    @extend %d-none;
                }
            }
            &-items {
                @extend %secondary-600-bg;
            }
        }
    }
    .no-data {
        @extend %text-center;
    }
    .waf-detail {
        padding: var(--space-4);
        body {
            background-color: var(--pure-white-900);
        }
        p {
            @extend %pb-5;
            @extend %font-14-pr;
            @extend %neutral-600;
        }
        .si-img-wrp {
            @extend %py-4;
        }
        .si-text-wrp {
            @extend %pb-4;
            > h3,h2 {
                @extend %mb-0;
                @extend %pb-4;
                @extend %neutral-800;
                @extend %font-16-pb;
            }
            p:last-child {
                @extend %pb-0;
            }
        }
        .article-head .head-wrap {
            margin-bottom: 0;
        }
    }
}
@include mq(col-md) {
    .waf-cricketscorecard {
        .layout-wrapper {
            max-width: var(--content-width);
        }
        .waf-detail {
            padding: var(--space-4);
        }
    }
    .waf-breadcrumb .breadcrumb-list {
        min-height: 4rem;
        height: auto;
        padding-block: var(--space-1);
    }
}
@include mq(col-lg) {
    .waf-cricketscorecard {
        margin-top: var(--space-4);
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .waf-detail {
            padding: var(--space-4);
            p {
                line-height: 2.2rem;
            }
            .si-text-wrp {
                > h3,h2 {
                    font-size: 1.8rem;
                }
            }
        }
    }
}