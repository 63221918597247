@use "../config/" as *;

.tabs {
    &-primary {
        overflow-x: auto;
        border-radius: 0;
        gap: 0;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .tab {
            &-name {
                min-width: 10.5rem;
                min-height: 4.8rem;
                @extend %p-1;
                &:not(:last-child) {
                    &::after {
                        content: "";
                        width: 0.1rem;
                        height: 1.4rem;
                        @include position-combo(y-center, 0);
                    }
                }
                &::after {
                    @extend %neutral-1100-bg;
                }
                &:first-child {
                    border-radius: 0;
                }
            }
            &-active::after {
                @extend %d-none;
            }
        }
    }
    .tab {
        &-text {
            @extend %capitalize;
            @extend %font-12-pm;
            @extend %neutral-600;
            &-count {
                height: 1.8rem;
                width: 1.8rem;
                @extend %ml-1;
                @extend %rounded-radius;
                @extend %font-8-pb;
                @extend %flex-c-c;
                @extend %secondary-600-bg;
                @extend %primary-300;
            }
        }
        &-name {
            cursor: pointer;
            @extend %relative;
            @extend %flex-c-c;
            &.tab-active {
                span,.tab-text {
                    font-weight: 700;
                    @extend %neutral-800;
                }
                .tab-text-count {
                    @extend %primary-300;
                }
            }
            &:last-child {
                @extend %mr-0;
            }
        }
    }
}
.waf-select-box {
    @extend %neutral-50-bg;
    .selected-title {
        .sub-title {
            @extend %d-none;
        }
        .title {
            @extend %neutral-800;
            @extend %capitalize;
            @extend %font-12-pm;
        }
    }
    .select-box-wrap {
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        background: var(--neutral-100);
        .list-item {
            min-height: 4rem;
        }
    }
    &::before {
        @extend %d-none;
    }
}