@use "../config/" as *;
.scorecard {
    @extend %p-4;
    @extend %pure-white-900-bg;
    .team {
        &-score {
            @extend %flex-column-c-c;
            @extend %gap-1;
            .runs,
            .overs {
                @extend %font-10-pm;
                @extend %neutral-800;
            }
            &-detail {
                @extend %my-3;
                @extend %flex-sb-c;
                @extend %px-2;
                @extend %py-1;
                @extend %half-radius;
                @extend %neutral-50-bg;
            }
            &-wrapper {
                @extend %flex;
                @extend %gap-1;
            }
        }
        &-detail {
            @extend %flex-c-c;
            @extend %gap-3;
        }
        &-flag {
            width: 3rem;
            height: 2rem;
        }
        &-logo {
            @extend %half-radius;
            @extend %w-100;
            @extend %h-100;
        }
        &-name {
            @extend %font-12-pb;
            @extend %neutral-800;
        }
    }
    .select-wrap {
        @extend %flex-sb-c;
        @extend %font-12-pb;
    }
    .waf-select-box {
        @extend %ml-auto;
        @extend %w-80;
        @extend %p-3;
        @extend %half-radius;
        .selected-title .sub-title {
            text-transform: none;
            max-width: 5rem;
            overflow-wrap: break-word;
            left: -8rem;
            @extend %pos-y-center;
            @extend %d-block;
            @extend %font-12-pb;
            @extend %neutral-900;
        }
        .select-box-wrap {
            @extend %neutral-50-bg;
        }
    }
    .over-runrate {
        @extend %font-10-pm;
        @extend %neutral-800;
    }
    .table {
        &-body {
            .action .text {
                @extend %font-10-pr;
            }
            & > .table-row {
                flex-direction: column;
                border-bottom: 0.1rem solid clr(neutral-1000);
            }
            .table-data {
                height: auto;
            }
        }
        &-row {
            border-bottom: none;
            @extend %px-3;
            &.accordion-active {
                border-bottom: none;
            }
            &.extra {
                flex-direction: row;
                border-bottom: none;
                @extend %mt-4;
                @extend %py-1;
                @extend %px-4;
                @extend %primary-50-bg;
                @extend %half-radius;
                .table-data {
                    flex: 1;
                    @extend %gap-1;
                    @extend %flex-column;
                    &:first-child {
                        align-items: flex-start;
                        width: 50%;
                        flex: unset;
                        .label {
                            @extend %capitalize;
                        }
                    }
                }
                .label,
                .value {
                    @extend %neutral-600;
                }
                .label {
                    @extend %font-10-pm;
                    @extend %uppercase;
                }
                .value {
                    @extend %font-10-pb;
                }
            }
        }
        &-data {
            flex: 1;
            &-wrapper {
                @extend %flex-n-c;
                @extend %gap-1;
            }
            &.label {
                flex-basis: 35%;
                justify-content: flex-start;
            }

        }
        &-head {
            @extend %neutral-1000-bg;
            .text {
                @extend %font-10-pb;
            }
            .table-data {
                min-height: 3rem;
                height: auto;
            }
        }
        &-wrap {
            @extend %my-3;
        }
        .text {
            text-transform: none;
            @extend %font-10-pr;
        }
    }
    .action {
        .text,
        .btn-text {
            font-size: 0;
        }
        .btn-text {
            width: 1.6rem;
            aspect-ratio: 1/1;
            @extend %primary-200-bg;
            @extend %rounded-radius;
            @extend %flex-c-c;
            &::after {
                rotate: 90deg;
                transition: .4s rotate ease-in;
                @include icon(circle-arrow-right, 12);
            }
        }
    }
    .player {
        &-data {
            @extend %text-left;
            .name {
                line-height: 1.8rem;
                @extend %text-left;
                @extend %capitalize;
                @extend %font-10-pb;
                @extend %neutral-800;
            }
            .info {
                @extend %flex-n-c;
                @extend %text-left;
            }
            .text {
                @extend %font-10-pr;
                @extend %neutral-600;
            }
        }
        &-thumbnail {
            flex-shrink: 0;
            width: 2.7rem;
            height: 2.7rem;
            @extend %hidden;
            @extend %circle-radius;
            @extend %secondary-50-bg;
            @extend %flex-c-c;
            .image {
                object-fit: contain;
                @extend %h-100;
            }
        }
    }
    .accordion {
        &-active {
            .btn-text::after {
                rotate: 270deg;
            }
            .accordion-body {
                grid-template-rows: 1fr;
                @extend %half-radius;
                @extend %neutral-50-bg;
                @extend %py-6;
            }
        }
        &-content {
            @extend %hidden;
        }
        &-head {
            @extend %py-3;
            .table-row {
                @extend %px-0;
            }
        }
        &-body {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows .4s ease-out, padding .4s ease-out;
            @extend %mx-3-neg;
            &[hidden] {
                display: none;
            }
        }
    }
    .controls {
        &-item {
            &:first-child {
                border-radius: var(--half-radius) 0 0 var(--half-radius);
            }
            &:last-child {
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
            }
        }
        &-content {
            line-height: 1.8rem;
            @extend %mt-6;
            @extend %font-12-pm;
            @extend %neutral-600;
        }
    }
    .svg-controls-section {
        @extend %px-4;
    }
    .total-scorecard {
        @extend %mt-2;
        @extend %mb-4;
        @extend %flex-sb-c;
        @extend %p-4;
        @extend %neutral-50-bg;
        @extend %half-radius;
        .label {
            @extend %capitalize;
            @extend %font-12-pb;
            @extend %neutral-800;
        }
        .text {
            @extend %font-12-pm;
            @extend %neutral-800;
        }
        .runs {
            @extend %font-15-pb;
            @extend %primary-1000;
        }
    }
    .wickets {
        @extend %py-3;
        @extend %neutral-50-bg;
        @extend %half-radius;
        .table {
            &-head {
                @extend %px-3;
                @extend %transparent-bg;
                .overs {
                    @extend %d-none;
                }
            }
            &-data {
                &.label {
                    @extend %w-100;
                }

            }
            &-body {
                overflow-x: auto;
                @extend %px-3;
                @extend %py-3;
                @extend %gap-3;
                @extend %flex;
                .table-row {
                    flex-direction: column;
                    padding-block: var(--space-3) var(--space-2);
                    min-width: 13rem;
                    border: 0.1rem solid clr(neutral-1000);
                    @extend %relative;
                    @extend %px-2;
                    @extend %pure-white-900-bg;
                    @extend %half-radius;
                }
                .table-data {
                    justify-content: center;
                }
            }
        }
        .player-data {
            .info {
                height: 2rem;
                top: -1rem;
                @extend %half-radius;
                @extend %pos-x-center;
                @extend %px-3;
                @extend %secondary-1000-bg;
                @extend %flex-c-c;
                .text {
                    @extend %text-center;
                    @extend %neutral-50;
                }
            }
            .name {
                @extend %font-10-pb;
                @extend %neutral-800;
            }
        }
        .overs {
            .text {
                @extend %font-10-pm;
                @extend %neutral-600;
            }
        }
    }
    .tab-partnership {
        .title {
            @extend %primary-1000;
            @extend %font-10-pb;
            @extend %px-3;
            @extend %py-2;
            @extend %my-0;
            @extend %neutral-1000-bg;
            @extend %capitalize;
            @extend %half-radius;
        }
        .partnership {
            &-list {
                @extend %gap-2;
                @extend %flex-column;
            }
            &-item {
                @extend %gap-2;
                @extend %py-2;
                @extend %px-3;
                @extend %flex;
            }
        }
        .name {
            @extend %capitalize;
            @extend %neutral-600;
            @extend %font-10-pm;
        }
        .score {
            .number {
                @extend %neutral-600;
                @extend %font-10-pm;
            }
            .highlight {
                @extend %font-10-pb;
                @extend %primary-1000;
            }
        }
        .player {
            width: 25%;
            &-data {
                width: 50%;
                @include flex-config(flex, column, null, center);
            }
            &-b {
                @extend %text-right;
            }
        }
        .progress-section {
            @extend %text-center;
            @extend %relative;
            @extend %mt-1;
            .label {
                z-index: var(--z-progress-bar-label);
                box-shadow: 0 .4rem 2.4rem 0 clr(neutral-900, 1.5);
                @extend %px-3;
                @extend %py-1;
                @extend %half-radius;
                @extend %relative;
                @extend %capitalize;
                @extend %font-10-pb;
                @extend %neutral-800;
                @extend %pure-white-900-bg;
            }
        }
        .team {
            height: 1rem;
            @extend %full-radius;
            @extend %neutral-50-bg;
            @extend %w-50;
            @extend %flex-n-c;
            .progress-bar {
                height: .4rem;
                @extend %full-radius;
                @extend %primary-300-bg;
                @extend %flex-n-c;
            }
            &-a {
                border-radius: var(--full-radius) 0 0 var(--full-radius);
                justify-content: flex-end;
            }
            &-b {
                border-radius: 0 var(--full-radius) var(--full-radius) 0;
                .progress-bar {
                    @extend %secondary-1000-bg;
                }
            }
        }
        .result {
            @include position-combo(center);
            @extend %flex;
            @extend %w-100;
        }
        .data {
            &-head {
                @extend %w-100;
                @extend %gap-4;
                @extend %flex-c-n;
                @extend %relative;
                &::before {
                    content: '';
                    width: .1rem;
                    @extend %h-100;
                    @extend %neutral-800-bg;
                    @extend %pos-x-center;
                }
            }
            &-item {
                // width: 100%;
                width: 50%;
                @extend %flex-n-c;
                // @extend %relative;
                // &:nth-child(2)::before {
                //     content: '';
                //     width: .1rem;
                //     left: -1rem;
                //     @extend %pos-y-center;
                //     @extend %neutral-800-bg;
                //     @extend %h-90;
                // }
                &:nth-child(1) {
                    justify-content: flex-end;
                }
                &:nth-child(2) {
                    justify-content: flex-start;
                }
                .label {
                    @extend %neutral-600;
                    @extend %font-10-pm;
                }
                .number {
                    @extend %neutral-800;
                    @extend %font-10-pb;
                }
            }
            &-body {
                @extend %w-100;
            }
        }
    }
    .bowler {
        .wickets {
            background: transparent;
            border-radius: 0;
        }
    }
}
@include mq(col-md) {
    .scorecard {
        .waf-select-box {
            width: 30%;
        }
        .spider-section {
            .svg-controls-section {
                width: 45%;
            }
            .svg-section {
                margin: var(--space-4) auto;
            }
            .inner-wrap {
                @include flex-config(flex, row-reverse, center, center);
            }
            .svg-section-container {
                width: 50%;
            }
        }
        .tab-partnership {
            .player {
                width: 15%;
                &-data {
                    width: 70%;
                }
            }
            .title {
                font-size: 1.4rem;
            }
            .score .number,
            .highlight {
                font-size: 1.2rem;
            }
            .progress-section {
                .label {
                    font-size: 1.2rem;
                }
            }
        }
        .team {
            &-name {
                font-size: 1.4rem;
            }
            &-score {
                .runs,
                .overs,.over-runrate {
                    font-size: 1.2rem;
                }
                &-detail {
                    padding-block: var(--space-2);
                }
            }
        }
        .player-data {
            .name {
                font-size: 1.2rem;
            }
        }
        .controls-content {
            font-size: 1.4rem;
        }
        .table {
            .text {
                font-size: 1.2rem;
            }
            &-data-wrapper {
                gap: var(--space-2);
            }
            &-row {
                &.extra {
                    height: 5rem;
                    padding-block: 0;
                    .table-data:first-child {
                        width: 30%;
                    }
                }
            }
        }
        .total-scorecard {
            .label {
                font-size: 1.4rem;
            }
        }
        .action {
            .btn-text {
                width: 2.4rem;
                &::after {
                    font-size: 1.6rem;
                }
            }
        }
    }
}