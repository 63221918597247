@use "../config/" as *;
.liveblog {
  @extend %p-4;
  @extend %pure-white-900-bg;
  .tabs-secondary {
    @extend %pb-3;
    @extend %gap-3;
    .tab {
      &-name {
        border: 0.1rem solid var(--neutral-600);
        min-height: 3rem;
        @extend %text-center;
        @extend %half-radius;
        &.tab-active {
          border: 0.1rem solid var(--primary-300);
        }
      }
    }
    .shortname {
      @extend %flex;
    }
    .fullname {
      @extend %d-none;
    }
  }
  .live-blog-time {
    width: max-content;
    @extend %my-2;
    @extend %py-8;
    @extend %relative;
    &:first-child {
      padding-top: 0;
      &::before {
        top: 50%;
        height: 50%;
      }
    }
    &:before {
      content: "";
      border-right: 0.15rem dashed var(--neutral-500);
      @extend %h-100;
      @include position(0, null, null, var(--space-8));
    }
    .text {
      width: max-content;
      height: 3rem;
      @extend %gap-2;
      @extend %relative;
      @extend %half-radius;
      @extend %font-14-pr;
      @extend %neutral-800;
      @extend %primary-300-bg;
      @extend %p-0-3;
      @extend %flex-c-c;
      &:before {
        @extend %secondary-800;
        @include icon(live-timer);
      }
    }
  }
  .liveblog-section {
    @extend %half-radius;
    @extend %p-4;
    @extend %neutral-50-bg;
    .text,
    p {
      line-height: 2rem;
      @extend %neutral-600;
      @extend %font-14-pr;
    }
    .social-content {
      @extend %flex-c-n;
      div {
        @extend %flex-c-n;
        @extend %w-100;
      }
    }
  }
  .liveblog-title {
    @extend %neutral-800;
    @extend %font-16-pb;
    @extend %mb-5;
  }
  .loadmore-wrap {
    .loadmore {
      @extend %uppercase;
      @include btn-animation;
      &::before {
        content: unset;
      }
    }
  }
  .over-info-wrapper {
    .head {
      @extend %mt-4;
      .over {
        &-text {
          @extend %uppercase;
          @extend %font-14-pb;
          @extend %neutral-800;
        }
        &-info {
          @extend %flex-sb-c;
          @extend %p-4;
          @extend %neutral-50-bg;
        }
      }
    }
    .over {
      &-details {
        min-width: 4.2rem;
        flex-shrink: 0;
        @extend %gap-2;
        @extend %flex-column-n-c;
        @extend %relative;
        &::after {
          content: "";
          width: 0.1rem;
          border-right: 0.1rem dashed var(--neutral-100);
          @include position(0, -1rem, null, null);
          @extend %h-100;
        }
      }
      &-item {
        border-bottom: 0.1rem solid var(--neutral-100);
        @extend %py-4;
        &.wicket {
          .over-score {
            border: 0.1rem solid var(--error-800);
            @extend %error-800-bg;
            .text {
              @extend %pure-white-900;
            }
          }
        }
        &.four {
          .over-score {
            border: 0.1rem solid var(--secondary-600);
            @extend %secondary-600-bg;
            .text {
              @extend %pure-white-900;
            }
          }
        }
        &.six {
          .over-score {
            border: 0.1rem solid var(--warning-900);
            @extend %warning-900-bg;
            .text {
              @extend %pure-white-900;
            }
          }
        }
        &.no-ball {
          .over-score {
            border: 0.1rem solid var(--secondary-100);
            @extend %secondary-100-bg;
            .text {
              @extend %pure-white-900;
            }
          }
        }
      }
      &-wrap {
        @extend %gap-5;
        @extend %flex;
      }
      &-count {
        .text {
          @extend %neutral-800;
          @extend %font-12-pb;
        }
      }
      &-score {
        border: 0.1rem solid var(--neutral-100);
        border-radius: 0.3rem;
        min-width: 2.2rem;
        @extend %relative;
        .text {
          @extend %p-1;
          @extend %flex-c-c;
          @extend %uppercase;
          @extend %neutral-800;
          @extend %font-12-psb;
        }
        .text-extra {
          width: 1.5rem;
          aspect-ratio: 1/1;
          @include position(-1rem, -0.8rem, null, null);
          @extend %flex-c-c;
          @extend %circle-radius;
          @extend %pure-white-900;
          @extend %neutral-800-bg;
          @extend %font-8-pm;
        }
      }
    }
    .commentary {
      &-title {
        @extend %mb-4;
        @extend %neutral-800;
        @extend %font-14-pb;
      }
      &-text {
        line-height: 2rem;
        @extend %neutral-600;
        @extend %font-14-pm;
      }
    }
  }
  .twitter-tweet,
  .instagram-media {
    @extend %mx-auto;
  }
  .btn-more-poll {
    @extend %ml-auto;
    @extend %mt-4;
    @extend %uppercase;
    @include btn-animation;
    &::before {
      content: unset;
    }
  }
  .polls-section {
    .poll {
      &-head {
        min-height: auto;
      }
    }
    .radio-box-btn,
    .btn-vote {
      @extend %w-100;
    }
  }
}
@include mq(col-md) {
  .liveblog {
    .over-info-wrapper {
      .over {
        &-details {
          min-width: 5rem;
        }
      }
    }
    .polls-section {
      .radio-box-btn {
        width: 30%;
        margin-left: auto;
      }
    }
  }
}
@include mq(col-lg) {
  .liveblog {
    .tabs-secondary {
      .shortname {
        display: none;
      }
      .fullname {
        @include flex-config(flex);
      }
    }
    .loadmore-wrap {
      .loadmore {
        &::before {
          content: "";
        }
      }
    }
    .btn-more-poll {
      &::before {
        content: "";
      }
    }
    .poll-item {
      width: 100%;
    }
  }
}
