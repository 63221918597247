@use "../config/" as *;
.polls,
.polls-section {
    .poll {
        &-list {
            @extend %gap-4;
            @extend %flex-wrap;
            @extend %flex-column;
            @extend %p-4;
            @extend %pure-white-900-bg;
        }
        &-item {
            border: .1rem solid var(--neutral-1000);
            @extend %w-100;
            @extend %half-radius;
            @extend %pure-white-900-bg;
            @extend %p-4;
        }
        &-head {
            min-height: 8rem;
            @extend %half-radius;
            @extend %flex-v-center;
            @extend %p-4-5;
            @extend %mb-4;
            @extend %secondary-50-bg;
        }
    }
    .title {
        @extend %neutral-800;
        @extend %font-12-pb;
    }
    .btn {
        &-vote {
            width: 21.7rem;
            min-height: 3.5rem;
            @extend %half-radius;
            @extend %p-2-5;
            @extend %primary-300-bg;
            &-active {
                .radio {
                    &-container {
                        pointer-events: none;
                        @extend %h-100;
                        @extend %flex-v-center;
                        input,
                        .radiomark {
                            @extend %d-none;
                        }
                    }
                    &-data {
                        z-index: var(--z-progress-bar);
                    }
                    &-box {
                        border: 0;
                        padding-block: 0;
                        height: 4rem;
                        @extend %neutral-50-bg;
                        @extend %relative;
                    }
                    &-box-btn {
                        @extend %d-none;
                    }
                }
                .progress {
                    &-count {
                        z-index: var(--z-progress-bar);
                        right: 0;
                        @extend %pos-y-center;
                        @extend %d-block;
                        @extend %font-10-pb;
                    }
                    &-bar-holder {
                        z-index: var(--z-progress-bar-holder);
                        width: calc(100% + var(--space-6));
                        left: var(--space-3-neg);
                        @extend %pos-y-center;
                        @extend %h-100;
                        @extend %flex;
                    }
                    &-bar {
                        @extend %half-radius;
                        @extend %h-100;
                        @extend %flex;
                        @extend %primary-300-bg;
                    }
                }
            }
        }
        &-text {
            @extend %font-12-pb;
            @extend %neutral-800;
            @extend %capitalize;
        }
    }
    .radio-box-wrap {
        @extend %gap-4;
        @extend %flex;
        @extend %flex-wrap;
        .radio-box {
            border: .1rem solid var(--neutral-1000);
            @extend %half-radius;
            @extend %w-100;
            @extend %p-3;
            &:has(.radio-input:checked) {
                @extend %primary-300-bg;
            }
        }
        .radio-container {
            cursor: pointer;
            user-select: none;
            @extend %relative;
            @extend %capitalize;
            @extend %font-12-pb;
            @extend %primary-1000;
            @extend %flex;
            input {
                opacity: 0;
                left: 0;
                cursor: pointer;
                @extend %d-none;
                @extend %pos-y-center;
                &:checked {
                    ~ .radiomark {
                        border: 0.4rem solid var(--secondary-1000);
                    }
                }
            }
            .radiomark {
                height: 1.8rem;
                width: 1.8rem;
                border: 0.1rem solid var(--neutral-1000);
                right: 0;
                @extend %circle-radius;
                @extend %pos-y-center;
                &:after {
                    width: 1rem;
                    aspect-ratio: 1/1;
                    @extend %circle-radius;
                    @extend %primary-300-bg;
                    @extend %pos-center;
                }
            }
        }
    }
    .progress-count,
    .progress-bar-holder {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .polls,
    .polls-section {
        .radio-box-wrap {
            .radio-box {
                width: calc((100% - var(--space-4)) / 2);
            }
        }
    }
}
@include mq(col-lg) {
    .polls,
    .polls-section {
        .poll-list {
            flex-direction: row;
            @include card-count(2, var(--space-4));
        }
    }
}